@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@48,700,1,200");

#canvas{
    background-color : #252b42;
    padding : 1rem 0rem;
}

h4{
  text-align: left;
  font-size: 2rem;
  color: aliceblue;
  margin-top: 4rem;
}

#row {
  display: flex;
  flex-wrap: wrap;
  padding: 1.5rem 0rem;
  justify-content: space-evenly;
  /* align-items: center; */
  margin : 1rem 4rem 0.5rem 4rem;
}

/* Create five equal columns that sits next to each other */
#card {
  flex:17%;
  max-width: 17%;
  padding: 0.4rem 0.4rem;
  margin-bottom: 4rem;
  background-color: rgb(248, 248, 248);
  height: 100%;
  box-shadow: 4px 4px 10px 5px #0b0c13d4;
}
#card:hover{
  transform: scale(1.05);
  transition: all;
  transition-duration: 150ms;
}

#card img:hover {opacity: 0.7;}

#card img {
  width: 100%;
  height : 20rem;
}

#card #button{
  color: hsl(229, 50%, 21%);;
  box-shadow: -1.3px 4px 4px 0px rgba(40, 38, 38, 0.5)
}

#card #button:hover{
  transition: all;
  transition-duration: 150ms;
  scale: 120%;
  box-shadow: -1.3px 4px 4px 0px rgba(40, 38, 38, 0.5);
  background-color: hsl(229, 50%, 21%);
  color: aliceblue;
  cursor: pointer;
}

#card .details{
  margin:0.5rem;
}

#card .name{
  margin: 0.7rem 0rem 0rem 0rem;
  font-size: large;
  font-weight: 900;
  color: black;
}

#loadmore{
  text-align: center;
  color: antiquewhite;
  font-size: large;
  background-color : #252b42;
  border : 2px solid whitesmoke;
  margin : 0% 2% 5% 46%;
  padding: 0.3rem 2rem
}

#loadmore:hover{
  transition: all;
  transition-duration: 150ms;
  color:black;
  font-weight: bold;
  background-color : whitesmoke;
  box-shadow: 2px 2px 5px 3px #161826d6;
  /* border : 2px solid rgb(59, 58, 58); */
  cursor: pointer;
  transform: scale(1.05);
}
  
/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 912px) {
  #card {
    flex: 40%;
    max-width: 40%;
    margin: 2rem;
  }
  #loadmore{
    margin : 0.5% 2% 15% 42%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  #card {
    flex: 100%;
    max-width: 100%;
    margin: 2rem;
  }
  #loadmore{
    margin : 0.5% 2% 15% 35%;
  }
}
