#canvas {
  background-color: #252b42;
  padding: 1rem 0rem;
}

#row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
  justify-content: space-around;
  align-items: center;
  margin: 4rem;
  margin-top: 2rem;
}

/* Create four equal columns that sits next to each other */
.column {
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
  display: flex;
  flex-flow: column wrap;
  align-self: flex-start;
}

.column img:hover {
  opacity: 0.7;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

#navigation {
  font-size: larger;
  font-weight: bolder;
}

.album_name {
  color: #fff;
  font-weight: normal;
  padding: 2px 9px;
}

.album_name:active,
.album_name:focus {
  border-bottom: 2px solid #fff; 
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .column {
    flex: 50%;
    max-width: 50%;
  }
  .gap-20 {
    gap: 2rem;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    flex: 100%;
    max-width: 100%;
  }
  .gap-20 {
    gap: 2rem;
  }
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 8rem; 
  /* padding-right: 4rem; */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.9);
}

/* Modal Content */
.card {
  position: relative;
  margin: auto;
  width: 50%;
  animation-name: animatetop;
  animation-duration: 0.4s
}