#blog{
    background-color: whitesmoke;
    border-radius: 6px;
    justify-content: start;
    justify-items: start;
}

#blog img{
    width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.blogcards{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 4rem 0rem 2rem 0rem;
    scale: 1
}

.bCard{
    flex : 22%;
    max-width: 22%;
    margin-bottom: 3rem;
    box-shadow: 4px 8px 14px 1px black;
}

.bCard:hover{
    transform: scale(1.025);
    box-shadow: 6px 10px 14px 1px black;

}

.Top-head{
    color: white;
    position: absolute;
    top: 1;
    padding: 0.5rem 1rem;
    z-index: 1;
}

.create-blog-container{
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 900px){
    .bCard{
        flex : 45%;
        max-width: 45%;
    }
    .create-blog-container{
        display: flex;
        flex-direction: row;
    }
}

@media screen and (max-width: 600px){
    .bCard{
        flex : 90%;
        max-width: 90%;
    }
    .create-blog-container{
        display: flex;
        flex-direction: column;
    }
    .create-blog-form input, .create-blog-form textarea{
        width: 100%;
    }
}
